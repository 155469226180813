<template>
    <div class="login-route">
        <language-select-unauthorized class="language-selector" />
        <clouds v-if="welcomeTheme === 'CLOUDS'" />
        <astypalaia v-else-if="welcomeTheme === 'ASTYPALAIA'" />
        <clouds-with-gifts v-else />
        <div class="login-container">
            <div class="login-title">
                <b-img
                    :src="branding.primaryLogoUrl"
                    class="mx-2 pt-2"
                    alt=" "
                />
            </div>
            <div class="login-text">
                {{ $t('loginRoute.guideline') }}
            </div>
            <form
                class="login-form"
                @submit.prevent="onSubmit"
            >
                <div class="input-group">
                    <label>{{ $t('loginRoute.accessCode') }}</label>
                    <input
                        v-model.trim="accessCode"
                        type="text"
                        maxLength="10"
                        :disabled="isCodeLoginDisabled"
                    >
                </div>
                <div class="or-container">
                    <hr><span>{{ $t('loginRoute.or') }}</span><hr>
                </div>
                <div class="username-login">
                    <div class="input-group">
                        <label>{{ $t('loginRoute.username') }}</label>
                        <input
                            v-model.trim="username"
                            type="text"
                            :disabled="isUsernameLoginDisabled"
                        >
                    </div>
                    <div class="input-group">
                        <label>{{ $t('loginRoute.password') }}</label>
                        <input
                            v-model.trim="password"
                            type="password"
                            :disabled="isUsernameLoginDisabled"
                        >
                    </div>
                </div>
                <div class="errors-container">
                    <span
                        v-if="loginError"
                        class="error-message"
                    >
                        {{ loginError }}
                    </span>
                </div>
                <button
                    type="submit"
                    :disabled="isSubmitDisabled"
                    class="custom-btn"
                    :class="{'disabled': isSubmitDisabled}"
                >
                    <span v-if="!isSubmitting">{{ $t('loginRoute.loginButton') }}</span>
                    <b-spinner v-else small />
                </button>
            </form>
            <router-link to="/forgot-password" class="reset-password">
                {{ $t('loginRoute.forgotPasswordText') }}
            </router-link>
            <router-link to="/welcome" class="back-button">
                {{ $t('loginRoute.back') }}
            </router-link>
        </div>
        <a
            v-if="branding.impressumPdfUrl"
            :href="branding.impressumPdfUrl || ''"
            class="impressum"
            target="_blank"
        >{{ $t('registerRoute.impressum') }}
        </a>
    </div>
</template>

<script>

    import CloudsWithGifts from '@/components/welcome-backgrounds/CloudsWithGifts'
    import Clouds from '@/components/welcome-backgrounds/Clouds'
    import Astypalaia from '@/components/welcome-backgrounds/Astypalaia'
    import LanguageSelectUnauthorized from '@/components/LanguageSelectUnauthorized'
    export default {
        components: {
            LanguageSelectUnauthorized,
            CloudsWithGifts,
            Clouds,
            Astypalaia
        },

        data() {
            return {
                accessCode: null,
                username: null,
                password: null,
                errorMap: {
                    INVALID_ACCESS_CODE: this.$t('loginRoute.invalidCode'),
                    INVALID_CREDENTIALS: this.$t('loginRoute.invalidCredentialsAlert')
                },
                loginError: null,
                isSubmitting: false
            }
        },
        computed: {
            isCodeLoginDisabled() {
                return this.username?.length > 0 || this.password?.length > 0
            },
            isUsernameLoginDisabled() {
                return this.accessCode?.length > 0
            },
            isSubmitDisabled() {
                return !this.isCodeLoginDisabled && !this.isUsernameLoginDisabled
            },
            branding() {
                return this.$store.getters.getBranding
            },
            welcomeTheme() {
                return this.$store.getters.getWelcomeTheme
            },
            accessCodeLength() {
                return this.$store.getters.getAccessCodeLength
            }
        },
        watch: {
            accessCode: {
                handler(newValue) {
                    if (newValue?.length === this.accessCodeLength) {
                        this.onSubmit()
                    }
                },
                immediate: true
            }
        },
        created() {
            this.$store.commit('clearFreeTaskInfo')
        },
        methods: {
            async onSubmit() {
                this.isSubmitting = true
                if (this.isCodeLoginDisabled) {
                    this.$store.dispatch('loginWithCredentials', {
                        username: this.username,
                        password: this.password
                    }).catch((error) => {
                        this.handleError(error)
                    }).finally(() => {
                        this.isSubmitting = false
                    })
                } else {
                    const reCaptchaResponse = await this.recaptcha('CODE_LOGIN')
                    this.$store.dispatch('loginWithAccessCode', { accessCode: this.accessCode, reCaptchaResponse })
                        .catch((error) => {
                            this.handleError(error)
                        })
                        .finally(() => {
                            this.isSubmitting = false
                        })
                }
            },
            handleError(error) {
                const errorResponse = error.response

                if (errorResponse.status === 401) {
                    this.loginError = this.errorMap['INVALID_CREDENTIALS']
                } else {
                    this.loginError = this.errorMap[errorResponse.data.code]
                }
                setTimeout(() => {
                    this.loginError = null
                }, 5000)
            }
        }
    }
</script>

<style lang="scss" scoped>
.login-route {
    width: 100%;
    min-height: 100vh;
    display: grid;
    place-items: center;
    position: relative;
}

.language-selector {
    position: absolute;
    top: 1%;
    left: 2%;
    z-index: 1;
}

.login-container {
    color: var(--white);
    max-width: 555px;
    width: 90%;
    margin: 0 auto;
    padding: 3rem 0 1rem;
    z-index: 1;
}

.login-title img {
    height: 95px;
}

.login-text {
    margin: 1.5rem;
    font-size: 1rem;
    line-height: 1.5;
}

.login-form {
    margin: 1rem auto 0;
    color: var(--white);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.75rem;
}

.username-login {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 100%;
}

.input-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.15rem;
    max-width: 420px;
    width: 80%;
}

.input-group label {
    align-self: start;
}

.input-group input {
    width: 100%;
    height: 50px;
    font-size: 1.25rem;
    padding: 0 0.5rem;
    border-radius: 5px;
    border: 0;
    color: #009fe3;
}

.input-group input.disabled {
    background-color: var(--disabled-color);
}

.or-container {
    display: flex;
    align-items: center;
    gap: 1rem;
}

hr {
    width: 80px;
    background: var(--white);
}

.errors-container {
    height: 1rem;
    font-weight: 700;
    color: var(--form-error-color);
}

.custom-btn {
    max-width: 420px;
    width: 80%;
    height: 60px;
    border-radius: 20px;
    display: grid;
    place-items: center;
    font-size: 1.1rem;
    letter-spacing: 0.3em;
    font-weight: 500;
    background: #193674;
    transition: all 0.35s ease-in-out;
    cursor: pointer;
    color: var(--white);
    text-decoration: none;
    border: none;
}

.custom-btn:hover:enabled {
    background: #f1b12b;
}

.custom-btn:active:enabled {
    transform: scale(0.9);
}

.custom-btn.disabled {
    background: var(--disabled-color);
    cursor: not-allowed;
}

.reset-password,
.back-button {
    display: block;
    margin-top: 0.75rem;
    font-size: 0.9rem;
    letter-spacing: 0.3em;
    color: var(--white);
}

.back-button {
    margin-top: 0.5rem;
}

.impressum {
    z-index: 1;
    padding: 0.25rem;
    color: white;
}

@media screen and (min-width: 768px) {
    .language-selector {
        left: 1%;
    }

    .login-text {
        margin: 2rem;
        line-height: 1.5;
        font-size: 1rem;
    }

    .login-form {
        margin-top: 2rem;
        gap: 1.25rem;
    }

    .input-group input {
        padding: 0 1rem;
    }

    .input-group label {
        font-size: 1rem;
    }

    .errors-container {
        height: 1.5rem;
    }

    .custom-btn {
        font-size: 1.25rem;
    }

    .reset-password {
        margin-top: 1.5rem;
    }

    .impressum {
        font-size: 1.25rem;
    }
}

</style>
